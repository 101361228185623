<template>
  <v-container class="pa-0">
    <!-- 選択肢(表示) -->
    <v-container>
      <v-row align="center">
        <v-col cols="auto">
          <span class="text-h3">{{ subtitle }}</span>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
            </template>
            <span>先頭のものが初期選択になります</span>
          </v-tooltip>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn color="primary" outlined @click="toggle">
            <v-icon class="mr-2">mdi-pencil</v-icon>
            <span class="text-h5">編集</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="tmpItems"
        hide-default-header
        hide-default-footer
        :items-per-page="-1"
        class="mt-4 elevation-1"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="(h, index) in headers" :key="index" :class="h.class">
                <span class="primary--text text-h5">{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            handle=".handle"
            :options="{ animation: 300 }"
            @end="onend(props.items)"
          >
            <tr v-for="(element, index) in props.items" :key="index">
              <td class="text-left handle">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-arrow-all </v-icon>
                  </template>
                  <span>ドラッグで上下に動かせます</span>
                </v-tooltip>
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ element.name }}</td>
              <td>{{ element.amount }}</td>
              <td class="text-left">
                <!-- <span class="red--text"> -->
                <span :class="createProvideStatusStyle(element.provideStatus)">
                  {{ createProvideStatusText(element.provideStatus) }}
                </span>
              </td>
              <td>{{ element.taxInclude }}</td>
              <td>{{ element.taxKbn }}</td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-container>

    <!-- 部品 -->
    <v-container>
      <v-dialog v-model="showConfirmAdd" max-width="300">
        <v-card>
          <v-card-title> 追加してよろしいですか? </v-card-title>
          <v-card-text class="pb-6 pt-12 text-center">
            <v-btn class="mr-3" text @click="confirmAdd(false)">
              キャンセル
            </v-btn>
            <v-btn color="primary" text @click="confirmAdd(true)">
              追加する
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="delInfo.show" max-width="300" persistent>
        <v-card>
          <v-card-title> 削除してよろしいですか? </v-card-title>
          <v-card-text class="pb-6 pt-12 text-center">
            <v-btn class="mr-3" text @click="delInfo.show = false">
              キャンセル
            </v-btn>
            <v-btn color="primary" text @click="confirmDel(delInfo)">
              削除する
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
const _cloneDeep = require("lodash.clonedeep");
import { PROVIDE_STATUS } from "../../../../constants";

export default {
  name: "SortableToggleListShow",
  components: {
    draggable,
  },
  props: {
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    datas: {
      type: Array, //参照渡し(Object/Array)
      required: true,
      default: () => [],
    },
    handleToggleMode: {
      type: Function,
      required: true,
    },
    handleSortChoices: {
      type: Function,
      required: true,
    },
  },
  computed: {},
  data: () => ({
    tmpItems: [],
    headers: [
      {
        text: "並べ替え",
        value: "",
        align: "start",
        sortable: false,
        class: "width10",
      },
      {
        text: "表示順",
        value: "order",
        align: "start",
        sortable: false,
        class: "width10",
      },
      {
        text: "名前",
        value: "name",
        align: "start",
        sortable: false,
      },
      {
        text: "金額",
        value: "amount",
        align: "start",
        sortable: false,
        class: "width15",
      },
      {
        text: "提供状態",
        value: "provideStatusId",
        class: "width15",
      },
      {
        text: "税有無",
        value: "taxInclude", //税込 or 税抜
        align: "start",
        sortable: false,
        class: "width10",
      },
      {
        text: "税区分",
        value: "taxKbn", //8%軽減 or ...
        align: "start",
        sortable: false,
        class: "width10",
      },
    ],
    showConfirmAdd: false,
    delInfo: { show: false, index: -1 },
  }),
  watch: {
    datas: {
      handler: function (newValue) {
        this.tmpItems = _cloneDeep(newValue);
      },
      deep: true,
      immediate: true, // 初回読込時も動作させる
    },
  },
  methods: {
    // 追加/編集 押下
    toggle: function () {
      this.handleToggleMode();
    },
    // ドラッグイベント
    onend(updatedItems) {
      // 反映 (子->親)
      this.handleSortChoices(updatedItems);
    },
    createProvideStatusText: function (provideStatusId) {
      return PROVIDE_STATUS.find((ps) => ps.id === provideStatusId)?.name ?? "";
    },
    createProvideStatusStyle: function (provideStatusId) {
      return provideStatusId === 0 ? "red--text" : "";
    },
  },
};
</script>
<style>
@import "../../../../css/sortableList.css";
</style>
