var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.subtitle))])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}])},[_c('span',[_vm._v("先頭のものが初期選択になります")])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.toggle}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")]),_c('span',{staticClass:"text-h5"},[_vm._v("編集")])],1)],1)],1),_c('v-data-table',{staticClass:"mt-4 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tmpItems,"hide-default-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h,index){return _c('th',{key:index,class:h.class},[_c('span',{staticClass:"primary--text text-h5"},[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody","handle":".handle","options":{ animation: 300 }},on:{"end":function($event){return _vm.onend(props.items)}}},_vm._l((props.items),function(element,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left handle"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-all ")])]}}],null,true)},[_c('span',[_vm._v("ドラッグで上下に動かせます")])])],1),_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(element.name))]),_c('td',[_vm._v(_vm._s(element.amount))]),_c('td',{staticClass:"text-left"},[_c('span',{class:_vm.createProvideStatusStyle(element.provideStatus)},[_vm._v(" "+_vm._s(_vm.createProvideStatusText(element.provideStatus))+" ")])]),_c('td',[_vm._v(_vm._s(element.taxInclude))]),_c('td',[_vm._v(_vm._s(element.taxKbn))])])}),0)]}}])})],1),_c('v-container',[_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.showConfirmAdd),callback:function ($$v) {_vm.showConfirmAdd=$$v},expression:"showConfirmAdd"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 追加してよろしいですか? ")]),_c('v-card-text',{staticClass:"pb-6 pt-12 text-center"},[_c('v-btn',{staticClass:"mr-3",attrs:{"text":""},on:{"click":function($event){return _vm.confirmAdd(false)}}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.confirmAdd(true)}}},[_vm._v(" 追加する ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"300","persistent":""},model:{value:(_vm.delInfo.show),callback:function ($$v) {_vm.$set(_vm.delInfo, "show", $$v)},expression:"delInfo.show"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 削除してよろしいですか? ")]),_c('v-card-text',{staticClass:"pb-6 pt-12 text-center"},[_c('v-btn',{staticClass:"mr-3",attrs:{"text":""},on:{"click":function($event){_vm.delInfo.show = false}}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.confirmDel(_vm.delInfo)}}},[_vm._v(" 削除する ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }